<template>
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col">
				<router-link to="/">&lt; Back to games</router-link>
			</div>
		</div>
		<div class="row mb-2">
			<div class="col-7">
				<h2 class="m-0">{{ file.fileName }}</h2>
				<div class="text-muted" v-if="file.shinyCharm">Shiny Charm</div>
			</div>
			<div class="col-5 text-right">
				<button type="button" class="btn btn-primary" @click="toggleModal">
					New Hunt
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-6 col-sm-4 col-md-3 col-xl-2" v-for="hunt in file.hunts" :key="hunt.huntId">
				<router-link
					:to="{ path: '/hunt', query: { gameId, fileId, huntId: hunt.huntId } }"
					class="card my-2 text-dark"
					:class="{
							'border-danger': hunt.huntStatus === '11',
							'border-success': hunt.huntStatus === '10'
						}"
				>
					<div
						class="card-header"
						:class="{
							'bg-warning': hunt.huntStatus === '01',
							'bg-danger': hunt.huntStatus === '11',
							'text-light': hunt.huntStatus === '11' || hunt.huntStatus === '10',
							'bg-success': hunt.huntStatus === '10'
						}"
					>
						<strong class="d-block text-truncate">{{ hunt.pokemon }}</strong>
					</div>
					<div class="card-body">
						<img class="shiny-img" :src="hunt.imageUrl" :alt="`Shiny ${hunt.pokemon}`" />
						<div>
							<strong>{{ huntMethods.find(method => method.methodId === hunt.methodId).name }}</strong>
						</div>
						<div>{{ `${getEncounterText(hunt.methodId)} ${hunt.encounters}` }}</div>
						<div>{{ getLastUpdatedText(hunt) }}</div>
						<div class="row mt-2 mt-sm-1">
							<div class="col-8 offset-2">
								<button
									type="button"
									class="btn btn-danger btn-block btn-sm"
									prevent-navigation
									@click="(e) => { e.preventDefault(); handleDeleteHunt(hunt.huntId); }"
								>
									{{ huntToDelete === hunt.huntId ? 'Confirm' : 'Delete' }}
								</button>
							</div>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
	<div class="modal fade" :class="{ show : showModal, 'd-block' : modalActive }" role="dialog" tabindex="-1">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">New Hunt - {{ file.fileName }}</h5>
					<button type="button" class="close" data-dismiss="modal" @click="toggleModal">&times;</button>
				</div>
				<div class="modal-body container-fluid">
					<div class="row">
						<div class="col m-0 justify-content-center form-check form-check-inline">
							<input
								type="radio"
								class="form-check-input"
								id="filter_all"
								name="form-filter"
								:checked="newHuntData.formFilter === ''"
								@change="newHuntData.formFilter = ''"
							/>
							<label class="form-check-label" for="filter_all">All</label>
						</div>
						<div class="col m-0 justify-content-center form-check form-check-inline">
							<input
								type="radio"
								class="form-check-input"
								id="filter_alola"
								name="form-filter"
								:checked="newHuntData.formFilter === 'Alolan '"
								@change="newHuntData.formFilter = 'Alolan '"
							/>
							<label class="form-check-label" for="filter_alola">Alolan</label>
						</div>
						<div
							class="col m-0 justify-content-center form-check form-check-inline"
							v-if="gameId.includes('swsh')"
						>
							<input
								type="radio"
								class="form-check-input"
								id="filter_galar"
								name="form-filter"
								:checked="newHuntData.formFilter === 'Galarian '"
								@change="newHuntData.formFilter = 'Galarian '"
							/>
							<label class="form-check-label" for="filter_galar">Galarian</label>
						</div>
					</div>
					<div class="row my-2 my-md-0">
						<div class="col text-center">
							<button
								type="button"
								class="btn btn-sm btn-link"
								:class="{ invisible : newHuntData.suggestion === '' }"
								@click="autocompletePokemon"
							>
								Suggestion: {{ newHuntData.suggestion }}
							</button>
						</div>
					</div>
					<div class="row form-group">
						<div class="col">
							<input
								type="text"
								class="form-control"
								autocomplete="off"
								placeholder="Find your Pokémon"
								v-model="newHuntData.pokemon"
								v-on:keyup="suggestPokemon"
								v-on:keyup.enter="autocompletePokemon"
							>
							<small
								class="d-block text-danger mt-1"
								:class="{ invisible: newHuntData.pokemonError === 'none' }"
							>
								{{ newHuntData.pokemonError }}
							</small>
						</div>
					</div>
					<div class="row form-group">
						<div class="col-12 mb-0 mb-md-2">
							<strong>Hunt Method</strong>
						</div>
						<div class="col-12 col-sm-6" v-for="method in huntMethods" :key="method.methodId">
							<div class="col form-check my-2 my-sm-1">
							<input
								type="radio"
								class="form-check-input"
								:id="`method-${method.methodId}`"
								name="hunt-method"
								:checked="newHuntData.methodId === method.methodId"
								@change="changeMethodType(method.methodId)"
							/>
							<label class="form-check-label" :for="`method-${method.methodId}`">
								{{ method.name }}
							</label>
						</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-center mb-2">
							<small class="d-block text-muted">
								Already started hunting? You can enter your number of {{ newHuntData.encounterDesc }} so far
							</small>
						</div>
						<div class="col-4 offset-4 form-group mb-0">
							<input
								type="number"
								class="form-control"
								min="0"
								placeholder="0"
								v-model="newHuntData.encounters"
							/>
						</div>
						<div class="col-12 mb-2 text-center">
							<small
								class="d-block text-danger mt-1"
								:class="{ invisible: newHuntData.encountersError === 'none' }"
							>
								{{ newHuntData.encountersError }}
							</small>
						</div>
					</div>
					<div class="row">
						<div class="col-4 offset-2 col-md-3 offset-md-3">
							<loading-button
								bsColor="success"
								:class="`btn-block mx-2`"
								:click="handleCreateHunt"
								:loading="creating"
								text="Save"
							/>
						</div>
						<div class="col-4 col-md-3">
							<button
								type="button"
								class="btn btn-block btn-outline-secondary"
								@click="toggleModal"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCurrentUser} from '../services/auth';
import { getFile, getHuntMethods, getNewHuntId, createNewHunt, deleteHunt } from '../services/games';
import LoadingButton from '../components/LoadingButton';
const pokemonList = require('../pkmn-data/pokemon.json');
const lockList = require('../pkmn-data/shiny-lock.json');

export default {
	components: { LoadingButton },
	data() {
		return {
			gameId: '',
			fileId: '',
			huntMethods: [],
			file: {},
			showModal: false,
			modalActive: false,
			newHuntData: {
				methodId: 0,
				pokemon: '',
				pokemonError: 'none',
				suggestion: '',
				encounters: 0,
				encounterDesc: 'encounters',
				encountersError: 'none',
				formFilter: '',
			},
			creating: false,
			huntToDelete: '',
		}
	},
	mounted() {
		const { query : { gameId, fileId } } = this.$route;
		if (!gameId || !fileId) {
			this.$router.replace('/');	
		} else {
			this.gameId = gameId;
			this.fileId = parseInt(fileId, 10);
			getHuntMethods((data) => {
				// TODO: Remove front-end filtering of hunt methods
				this.huntMethods = data.map((method, index) => ({
					...method,
					methodId : index,
				})).filter(method => !method.gen || method.gen === (gameId.includes('swsh') ? 8 : 7));
				this.newHuntData.methodId = this.huntMethods[0].methodId;
				this.retrieveHunts();
			});
		}
	},
	methods: {
		retrieveHunts() {
			const user = getCurrentUser();
			getFile(user.uid, this.gameId, this.fileId, (file) => {
				this.file = file;
				if (file.hunts) {
					this.file.hunts = Object.entries(file.hunts)
						.map(([key, hunt]) => ({ ...hunt, key }))
						.sort((a, b) => b.updated - a.updated);
				} else this.file.hunts = [];
			});
		},
		toggleModal() {
			if (this.modalActive) {
				this.showModal = !this.showModal;
				setTimeout(() => {
					this.modalActive = !this.modalActive;
					clearTimeout();
				}, 100);
			} else {
				this.huntToDelete = '';
				this.modalActive = ! this.modalActive;
				setTimeout(() => {
					this.showModal = !this.showModal;
					clearTimeout();
				}, 100);
			}
		},
		suggestPokemon() {
			const { pokemon, suggestion, formFilter } = this.newHuntData;
			if (pokemon.trim().length > 0) {
				this.newHuntData.pokemonError = 'none';
				let shinyLocked = lockList.find(game => game.id === 'pkmn_all').data;
				if (lockList.find(game => game.id === this.gameId)) {
					shinyLocked = shinyLocked.concat(lockList.find((game) => game.id === this.gameId).data);
				}
				const available = pokemonList.filter(pkmn => (
					!shinyLocked.includes(pkmn) && pkmn.includes(formFilter)
				)).map(pkmn => pkmn.replace(formFilter, ''));
				const suggestions = available.filter((pkmn) => {
					return pkmn.toLowerCase().substr(0, pokemon.length) === pokemon.toLowerCase();
				});
				if (suggestions.length === 1 && pokemon !== suggestions[0]) {
					this.newHuntData.suggestion = suggestions[0];
				} else if (suggestion.length > 0) this.newHuntData.suggestion = '';
			} else if (suggestion.length > 0) this.newHuntData.suggestion = '';
		},
		autocompletePokemon() {
			if (this.newHuntData.suggestion !== '') {
				this.newHuntData.pokemon = this.newHuntData.suggestion;
				this.newHuntData.suggestion = '';
			}
		},
		changeMethodType(methodId) {
			this.newHuntData.methodId = methodId;
			if (methodId === 1) this.newHuntData.encounterDesc = 'hatches';
			else this.newHuntData.encounterDesc = 'encounters';
		},
		handleCreateHunt() {
			const { pokemon, formFilter, encounters, methodId } = this.newHuntData;
			this.newHuntData.pokemonError = 'none';
			this.newHuntData.encountersError = 'none';
			if (pokemon.trim() === '') this.newHuntData.pokemonError = 'Pokémon is required';
			else if (!pokemonList.includes(`${formFilter}${pokemon}`)) this.newHuntData.pokemonError = 'Not a Pokémon';
			if (encounters.toString().includes('-') || Number.isNaN(parseInt(encounters, 10))) {
				this.newHuntData.encountersError = 'Not a valid number';
			}
			if (this.newHuntData.pokemonError === 'none' && this.newHuntData.encountersError === 'none') {
				this.creating = true;
				getNewHuntId(huntId => {
					const user = getCurrentUser();
					const hunt = {
						huntId,
						methodId,
						pokemon: `${formFilter}${pokemon}`,
						encounters: parseInt(encounters, 10),
						startDate: new Date().getTime(),
						huntStatus: '00',
						imageUrl: this.getImageUrl(`${formFilter}${pokemon}`),
					};
					createNewHunt(user.uid, this.gameId, this.fileId, hunt, (err) => {
						this.creating = false;
						console.log(err);
						return;
					});
					this.creating = false;
					this.retrieveHunts();
					this.newHuntData = {
						methodId: this.huntMethods[0].methodId,
						pokemon: '',
						pokemonError: 'none',
						suggestion: '',
						encounters: 0,
						encounterDesc: 'encounters',
						encountersError: 'none',
						formFilter: '',
					}
					this.toggleModal();
				})
			}
		},
		getLastUpdatedText(hunt) {
			const { huntStatus, updated } = hunt;
			const startDate = new Date(hunt.startDate).toLocaleString(undefined, {
				year  : '2-digit',
				month : 'numeric',
				day   : 'numeric',
			});
			if (huntStatus === '00') return `Created ${startDate}`;
			const msInDay = 1000 * 60 * 60 * 24;
			const diff = Math.floor((new Date().getTime() - updated) / msInDay);
			let statusText = `Updated ${updated && diff >= 2 ? 'on ' : ''}`;
			switch (huntStatus) {
				case '10':
					statusText = 'Completed:';
					break;
				case '11':
					statusText = 'Gave up:';
					break;
				default:
					break;
			}
			if (!updated || diff === 0) return `${statusText} recently`;
			if (diff === 1) return `${statusText} yesterday`;
			const updatedDate = new Date(updated).toLocaleString(undefined, {
				year  : '2-digit',
				month : 'numeric',
				day   : 'numeric',
			});
			return `${statusText} ${updatedDate}`;
		},
		getEncounterText(methodId) {
			switch (methodId) {
				case 1:
					return 'Hatches:';
				case 2:
					return 'Chain:';
				case 4:
					return 'Resets:';
				case 0:
				case 3:
				default:
					return 'Encounters:'
			}
		},
		getImageUrl(name) {
			const cleanName = name.replace(/(alolan\s)|(galarian\s)/gi, '');
			const cleanDex = pokemonList.filter(p => (
				!p.toLowerCase().includes('galarian') && !p.toLowerCase().includes('alolan')
			));
			const dexNumber = cleanDex.findIndex(mon => mon === cleanName) + 1;
			let dexString = dexNumber.toString();
			if (dexNumber < 10) dexString = `00${dexNumber}`;
			if (dexNumber < 100) dexString = `0${dexNumber}`;
			if (name.toLowerCase().includes('galarian')) dexString += '-g';
			else if (name.toLowerCase().includes('alolan')) dexString += '-a';

			let dex = 'SWSH';
			if (!this.gameId.includes('swsh')) dex = 'SM'
			return `https://serebii.net/Shiny/${dex}/${dexString}.png`;
		},
		handleDeleteHunt(huntId) {
			if (this.huntToDelete === huntId) {
				const user = getCurrentUser();
				deleteHunt(user.uid, this.gameId, this.fileId, parseInt(huntId, 10), (hunts) => {
					if (hunts) {
						this.file.hunts = Object.entries(hunts)
							.map(([key, hunt]) => ({ ...hunt, key }))
							.sort((a, b) => b.updated - a.updated);
					} else this.file.hunts = [];
				});
			} else {
				this.huntToDelete = huntId;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.modal {
	background-color: rgba(0, 0, 0, 0.5);

	.form-check-inline {
		padding-left: 15px;
	}
}
.card {
	cursor: pointer;
	box-shadow: none;
	transition: box-shadow .2s ease-out;

	&:hover {
		text-decoration: none;
		box-shadow: 1px 4px 10px 0 #2c2c2c;
		transition: box-shadow .2s ease-out;
	}

	> .card-body {
		font-size: 80%;
		padding: 0.75rem;
		white-space: nowrap;
	}

	@media (min-width: 768px) {
		> .card-body {
			padding: 1.25rem;;
		}
	}
}
</style>